// import React, { useState } from 'react';
// import Swal from 'sweetalert2';
// import { saveAs } from 'file-saver';
// import { useDropzone } from 'react-dropzone';


// const FileUpload = () => {
//   const [files, setFiles] = useState([]);
//   const [isLoading, setIsLoading] = useState(false);
//   const [downloadUrl, setDownloadUrl] = useState(null);
//   const [isDownloading, setIsDownloading] = useState(false); // New state for download loading

//   const onDrop = acceptedFiles => {
//     setFiles(acceptedFiles);
//   };

//   const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: '.eml' });

//   const convertFiles = async () => {
//     if (files.length === 0 || isLoading) {
//       return;
//     }
  
//     setIsLoading(true);
  
//     // Send files to backend for conversion
//     const formData = new FormData();
//     files.forEach(file => formData.append('emailFiles', file));
  
//     try {
//       // const response = await fetch('https://msg.quickcoupondeals.com/convert', {
//           const response = await fetch('http://localhost:4008/convert', {
//         method: 'POST',
//         body: formData,
//       });
  
//       if (response.ok) {
//         const result = await response.json();
//         setDownloadUrl(result.zipPath);
//         Swal.fire({
//           icon: 'success',
//           title: 'Conversion Successful',
//           text: 'Your files have been converted successfully.',
//         });
//       } else {
//         throw new Error('Conversion failed');
//       }
//     } catch (error) {
//       console.error('Error converting files:', error);
//       Swal.fire({
//         icon: 'error',
//         title: 'Conversion Error',
//         text: 'An error occurred during conversion. Please try again.',
//       });
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const downloadConvertedFiles = async () => {
//     if (!downloadUrl || isDownloading) { // Check if download is already in progress
//       return;
//     }

//     setIsDownloading(true); // Set download loading state
  
//     // const fullUrl = `https://msg.quickcoupondeals.com${downloadUrl}`;
//     const fullUrl = `http://localhost:4008${downloadUrl}`;

//     try {
//       const response = await fetch(fullUrl, {
//         method: 'GET',
//       });
  
//       if (response.ok) {
//         const blob = await response.blob();
//         saveAs(blob, 'converted_files.zip');
//       } else {
//         throw new Error('Download failed');
//       }
//     } catch (error) {
//       console.error('Error downloading files:', error);
//       Swal.fire({
//         icon: 'error',
//         title: 'Download Error',
//         text: 'An error occurred during download. Please try again.',
//       });
//     } finally {
//       setIsDownloading(false); // Reset download loading state
//     }
//   };
  
//   const firstFileName = files.length > 0 ? files[0].name : '';

//   return (
//     <>

// <div className="container" style={{border:'2px dashed black', cursor:'pointer' , position:'relative'}}>
//   <div className="row justify-content-center">
//     <div className="col-8">
//       <p className="text-center pt-5 pb-3">Preview your data on browser and keep your data safe on device</p>
//       <div
//         {...getRootProps()}
//         className="dropzone text-center p-3"
//         style={{ cursor: 'pointer' }}
//       >
//         <button className="btn btn-primary">
//           <input {...getInputProps()} accept=".msg" />
//           <p className="h4 mb-0 fw-bold py-3 text-white px-4 stretched-link">
//           {files.length === 0 ? 'Select Files' : `${firstFileName} ${files.length > 1 ? `+${files.length - 1}` : ''}`}
//           </p>
//         </button>
//         <p className="pt-4 pb-5">Your data stays safe on your device, and previews happen in your web browser</p>
//       </div>
//     </div>
//   </div>
// </div>

   
// <>

// <div className="row mt-3">
//   <div className="col text-center">
//     <button
//       className="btn btn-primary btn-block"
//       onClick={convertFiles}
//       disabled={isLoading || files.length === 0}
//     >
//       {isLoading ? 'Converting...' : 'Convert'}
//     </button>
//   </div>
//   <div className="col">
//     <button
//       className="btn btn-success btn-block"
//       onClick={downloadConvertedFiles}
//       disabled={!downloadUrl}
//     >
//       {isDownloading ? 'Downloading...' : 'Download Converted Files'}
//     </button>
//   </div>
// </div>

  
// </>
//     </>
//   );
// };

// export default FileUpload;





// import React, { useState } from 'react';
// import Swal from 'sweetalert2';
// import { saveAs } from 'file-saver';
// import { useDropzone } from 'react-dropzone';

// const FileUpload = () => {
//   const [files, setFiles] = useState([]);
//   const [isLoading, setIsLoading] = useState(false);
//   const [downloadUrl, setDownloadUrl] = useState(null);
//   const [isDownloading, setIsDownloading] = useState(false); // New state for download loading

//   const onDrop = acceptedFiles => {
//     setFiles(acceptedFiles);
//   };

//   const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: '.eml' });

//   const convertFiles = async () => {
//     if (files.length === 0 || isLoading) {
//       return;
//     }

//     setIsLoading(true);

//     // Send files to backend for conversion
//     const formData = new FormData();
//     files.forEach(file => formData.append('emailFiles', file));

//     try {
//       // const response = await fetch('https://msg.quickcoupondeals.com/convert', {
//       const response = await fetch('http://localhost:4008/convert', {
//         method: 'POST',
//         body: formData,
//       });

//       if (response.ok) {
//         const result = await response.json();
//         setDownloadUrl(result.zipPath);
//         Swal.fire({
//           icon: 'success',
//           title: 'Conversion Successful',
//           text: 'Your files have been converted successfully.',
//         });
//       } else {
//         throw new Error('Conversion failed');
//       }
//     } catch (error) {
//       console.error('Error converting files:', error);
//       Swal.fire({
//         icon: 'error',
//         title: 'Conversion Error',
//         text: 'An error occurred during conversion. Please try again.',
//       });
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const downloadConvertedFiles = async () => {
//     if (!downloadUrl || isDownloading) { // Check if download is already in progress
//       return;
//     }

//     setIsDownloading(true); // Set download loading state

//     // const fullUrl = `https://msg.quickcoupondeals.com${downloadUrl}`;
//     const fullUrl = `http://localhost:4008${downloadUrl}`;

//     try {
//       const response = await fetch(fullUrl, {
//         method: 'GET',
//       });

//       if (response.ok) {
//         const blob = await response.blob();
//         saveAs(blob, 'converted_files.zip');
//       } else {
//         throw new Error('Download failed');
//       }
//     } catch (error) {
//       console.error('Error downloading files:', error);
//       Swal.fire({
//         icon: 'error',
//         title: 'Download Error',
//         text: 'An error occurred during download. Please try again.',
//       });
//     } finally {
//       setIsDownloading(false); // Reset download loading state
//     }
//   };

//   const removeFile = index => {
//     const updatedFiles = [...files];
//     updatedFiles.splice(index, 1);
//     setFiles(updatedFiles);
//   };

//   const firstFileName = files.length > 0 ? files[0].name : '';

//   return (
//     <div>
//       <div className="container" style={{ border: '2px dashed black', cursor: 'pointer', position: 'relative' }}>
//         <div className="row justify-content-center">
//           <div className="col-8">
//             <p className="text-center pt-5 pb-3">Preview your data on browser and keep your data safe on device</p>
//             <div
//               {...getRootProps()}
//               className="dropzone text-center p-3"
//               style={{ cursor: 'pointer' }}
//             >
//               <button className="btn btn-primary">
//                 <input {...getInputProps()} accept=".msg" />
//                 <p className="h4 mb-0 fw-bold py-3 text-white px-4 stretched-link">
//                   {files.length === 0 ? 'Select Files' : `${firstFileName} ${files.length > 1 ? `+${files.length - 1}` : ''}`}
//                 </p>
//               </button>
//               <p className="pt-4 pb-5">Your data stays safe on your device, and previews happen in your web browser</p>
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* <div className="container mt-4">
//         <div className="row">
//           <div className="col">
//             <h4>Uploaded Files</h4>
//             <ul className="list-group">
//               {files.map((file, index) => (
//                 <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
//                   {file.name} ({(file.size / 1024).toFixed(2)} KB)
//                   <button className="btn btn-danger btn-sm" onClick={() => removeFile(index)}>Remove</button>
//                 </li>
//               ))}
//             </ul>
//           </div>
//         </div>
//       </div> */}

// {/* 
// <div className="container mt-4">
//   <div className="row">
//     <div className="col">
//       <h4>Uploaded Files</h4>
//       <ul className="list-group">
//         {files.map((file, index) => (
//           <li key={index} className="list-group-item d-flex flex-column">
//             <div className="d-flex justify-content-between align-items-center mb-2">
//               <span>{file.name}</span>
//               <button className="btn btn-danger btn-sm" onClick={() => removeFile(index)}>Remove</button>
//             </div>
//             <div className="d-flex justify-content-between align-items-center">
//               <span>File Size: {(file.size / 1024).toFixed(2)} KB</span>
//             </div>
//           </li>
//         ))}
//       </ul>
//     </div>
//   </div>
// </div> */}



// <div className="container mt-4" style={{ overflowX: 'auto' }}>
//   <table className="table">
//     <thead>
//       <tr>
//         <th scope="col">File Name</th>
//         <th scope="col">File Size</th>
//         <th scope="col">Progress</th>
//         <th scope="col">Actions</th>
//       </tr>
//     </thead>
//     <tbody>
//       {files.map((file, index) => (
//         <tr key={index}>
//           <td>{file.name}</td>
//           <td>{(file.size / 1024).toFixed(2)} KB</td>
//           <td>Pending {/* Replace with file status */}</td>
//           <td>
//             <button className="btn btn-danger btn-sm" onClick={() => removeFile(index)}>×</button>
//           </td>
//         </tr>
//       ))}
//     </tbody>
//   </table>
// </div>



//       <div className="row mt-3">
//         <div className="col text-center">
//           <button
//             className="btn btn-primary btn-block"
//             onClick={convertFiles}
//             disabled={isLoading || files.length === 0}
//           >
//             {isLoading ? 'Converting...' : 'Convert'}
//           </button>
//         </div>
//         <div className="col">
//           <button
//             className="btn btn-success btn-block"
//             onClick={downloadConvertedFiles}
//             disabled={!downloadUrl}
//           >
//             {isDownloading ? 'Downloading...' : 'Download Converted Files'}
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default FileUpload;




// import React, { useState } from 'react';
// import Swal from 'sweetalert2';
// import { saveAs } from 'file-saver';
// import { useDropzone } from 'react-dropzone';
// import './App.css';

// const FileUpload = () => {
//   const [files, setFiles] = useState([]);
//   const [isLoading, setIsLoading] = useState(false);
//   const [downloadUrl, setDownloadUrl] = useState(null);
//   const [isDownloading, setIsDownloading] = useState(false); // New state for download loading

//   const onDrop = acceptedFiles => {
//     setFiles([...files, ...acceptedFiles]);
//   };

//   const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: '.eml' });

//   const convertFiles = async () => {
//     if (files.length === 0 || isLoading) {
//       return;
//     }

//     setIsLoading(true);

//     // Send files to backend for conversion
//     const formData = new FormData();
//     files.forEach(file => formData.append('emailFiles', file));

//     try {
//       // const response = await fetch('https://msg.quickcoupondeals.com/convert', {
//       const response = await fetch('http://localhost:4008/convert', {
//         method: 'POST',
//         body: formData,
//       });

//       if (response.ok) {
//         const result = await response.json();
//         setDownloadUrl(result.zipPath);
//         Swal.fire({
//           icon: 'success',
//           title: 'Conversion Successful',
//           text: 'Your files have been converted successfully.',
//         });
//       } else {
//         throw new Error('Conversion failed');
//       }
//     } catch (error) {
//       console.error('Error converting files:', error);
//       Swal.fire({
//         icon: 'error',
//         title: 'Conversion Error',
//         text: 'An error occurred during conversion. Please try again.',
//       });
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const downloadConvertedFiles = async () => {
//     if (!downloadUrl || isDownloading) { // Check if download is already in progress
//       return;
//     }

//     setIsDownloading(true); // Set download loading state

//     // const fullUrl = `https://msg.quickcoupondeals.com${downloadUrl}`;
//     const fullUrl = `http://localhost:4008${downloadUrl}`;

//     try {
//       const response = await fetch(fullUrl, {
//         method: 'GET',
//       });

//       if (response.ok) {
//         const blob = await response.blob();
//         saveAs(blob, 'converted_files.zip');
//       } else {
//         throw new Error('Download failed');
//       }
//     } catch (error) {
//       console.error('Error downloading files:', error);
//       Swal.fire({
//         icon: 'error',
//         title: 'Download Error',
//         text: 'An error occurred during download. Please try again.',
//       });
//     } finally {
//       setIsDownloading(false); // Reset download loading state
//     }
//   };

//   const removeFile = index => {
//     const updatedFiles = [...files];
//     updatedFiles.splice(index, 1);
//     setFiles(updatedFiles);
//   };

//   const firstFileName = files.length > 0 ? files[0].name : '';

//   return (
//     <div>
//       <div className="container" style={{ border: '2px dashed black', cursor: 'pointer', position: 'relative' }}>
//         <div className="row justify-content-center">
//           <div className="col-8">
//             <p className="text-center pt-5 pb-3">Preview your data on browser and keep your data safe on device</p>
//             <div
//               {...getRootProps()}
//               className="dropzone text-center p-3"
//               style={{ cursor: 'pointer' }}
//             >
//               <button className="btn btn-primary">
//                 <input {...getInputProps()} accept=".msg" />
//                 <p className="h4 mb-0 fw-bold py-3 text-white px-4 stretched-link">
//                   {files.length === 0 ? 'Select Files' : `${firstFileName} ${files.length > 1 ? `+${files.length - 1}` : ''}`}
//                 </p>
//               </button>
//               <p className="pt-4 pb-5">Your data stays safe on your device, and previews happen in your web browser</p>
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className="container mt-4" style={{ overflowX: 'auto' }}>
//         <table className="table">
//           <thead>
//             <tr>
//               <th scope="col">File Name</th>
//               <th scope="col">File Size</th>
//               <th scope="col">Progress</th>
//               <th scope="col">Actions</th>
//             </tr>
//           </thead>
//           <tbody>
//             {files.map((file, index) => (
//               <tr key={index} className={file.removed ? "fade-out" : ""}>
//                 <td>{file.name}</td>
//                 <td>{(file.size / 1024).toFixed(2)} KB</td>
//                 <td>Pending {/* Replace with file status */}</td>
//                 <td>
//                   <button className="btn btn-danger btn-sm" onClick={() => removeFile(index)}>×</button>
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>

//       <div className="row mt-3">
//         <div className="col text-center">
//           <button
//             className="btn btn-primary btn-block"
//             onClick={convertFiles}
//             disabled={isLoading || files.length === 0}
//           >
//             {isLoading ? 'Converting...' : 'Convert'}
//           </button>
//         </div>
//         <div className="col">
//           <button
//             className="btn btn-success btn-block"
//             onClick={downloadConvertedFiles}
//             disabled={!downloadUrl}
//           >
//             {isDownloading ? 'Downloading...' : 'Download Converted Files'}
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default FileUpload;



// import React, { useState } from 'react';
// import Swal from 'sweetalert2';
// import { saveAs } from 'file-saver';
// import { useDropzone } from 'react-dropzone';
// import './App.css';

// const FileUpload = () => {
//   const [files, setFiles] = useState([]);
//   const [isLoading, setIsLoading] = useState(false);
//   const [downloadUrl, setDownloadUrl] = useState(null);
//   const [isDownloading, setIsDownloading] = useState(false); // New state for download loading

//   const onDrop = acceptedFiles => {
//     setFiles([...files, ...acceptedFiles]);
//   };

//   const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: '.eml' });

//   const convertFiles = async () => {
//     if (files.length === 0 || isLoading) {
//       return;
//     }

//     setIsLoading(true);

//     // Send files to backend for conversion
//     const formData = new FormData();
//     files.forEach(file => formData.append('emailFiles', file));

//     try {
//       // const response = await fetch('https://msg.quickcoupondeals.com/convert', {
//       const response = await fetch('http://localhost:4008/convert', {
//         method: 'POST',
//         body: formData,
//       });

//       if (response.ok) {
//         const result = await response.json();
//         setDownloadUrl(result.zipPath);
//         Swal.fire({
//           icon: 'success',
//           title: 'Conversion Successful',
//           text: 'Your files have been converted successfully.',
//         });
//       } else {
//         throw new Error('Conversion failed');
//       }
//     } catch (error) {
//       console.error('Error converting files:', error);
//       Swal.fire({
//         icon: 'error',
//         title: 'Conversion Error',
//         text: 'An error occurred during conversion. Please try again.',
//       });
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const downloadConvertedFiles = async () => {
//     if (!downloadUrl || isDownloading) { // Check if download is already in progress
//       return;
//     }

//     setIsDownloading(true); // Set download loading state

//     // const fullUrl = `https://msg.quickcoupondeals.com${downloadUrl}`;
//     const fullUrl = `http://localhost:4008${downloadUrl}`;

//     try {
//       const response = await fetch(fullUrl, {
//         method: 'GET',
//       });

//       if (response.ok) {
//         const blob = await response.blob();
//         saveAs(blob, 'converted_files.zip');
//       } else {
//         throw new Error('Download failed');
//       }
//     } catch (error) {
//       console.error('Error downloading files:', error);
//       Swal.fire({
//         icon: 'error',
//         title: 'Download Error',
//         text: 'An error occurred during download. Please try again.',
//       });
//     } finally {
//       setIsDownloading(false); // Reset download loading state
//     }
//   };

//   // const removeFile = index => {
//   //   const updatedFiles = [...files];
//   //   updatedFiles[index].removed = true; // Mark file as removed
//   //   setFiles(updatedFiles);
//   // };


//   const removeFile = index => {
//     const updatedFiles = files.filter((_, i) => i !== index);
//     setFiles(updatedFiles);
//   };
  

//   const firstFileName = files.length > 0 ? files[0].name : '';

//   return (
//     <div>
//       <div className="container" style={{ border: '2px dashed black', cursor: 'pointer', position: 'relative' }}>
//         <div className="row justify-content-center">
//           <div className="col-8">
//             <p className="text-center pt-5 pb-3">Preview your data on browser and keep your data safe on device</p>
//             <div
//               {...getRootProps()}
//               className="dropzone text-center p-3"
//               style={{ cursor: 'pointer' }}
//             >
//               <button className="btn btn-primary">
//                 <input {...getInputProps()} accept=".msg" />
//                 <p className="h4 mb-0 fw-bold py-3 text-white px-4 stretched-link">
//                   {files.length === 0 ? 'Select Files' : `${firstFileName} ${files.length > 1 ? `+${files.length - 1}` : ''}`}
//                 </p>
//               </button>
//               <p className="pt-4 pb-5">Your data stays safe on your device, and previews happen in your web browser</p>
//             </div>
//           </div>
//         </div>
//       </div>

//       {files.length > 0 && (
//         <div className="container mt-4" style={{ overflowX: 'auto' }}>
//           <table className="table">
//             <thead>
//               <tr>
//                 <th scope="col">File Name</th>
//                 <th scope="col">File Size</th>
//                 <th scope="col">Progress</th>
//                 <th scope="col">Actions</th>
//               </tr>
//             </thead>
//             <tbody>
//               {files.map((file, index) => (
//                 <tr key={index} className={file.removed ? "fade-out" : ""}>
//                   <td>{file.name}</td>
//                   <td>{(file.size / 1024).toFixed(2)} KB</td>
//                   <td>Pending {/* Replace with file status */}</td>
//                   <td>
//                     <button className="btn btn-danger btn-sm" onClick={() => removeFile(index)}>×</button>
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>
//       )}

//       <div className="row mt-3">
//         <div className="col text-center">
//           <button
//             className="btn btn-primary btn-block"
//             onClick={convertFiles}
//             disabled={isLoading || files.length === 0}
//           >
//             {isLoading ? 'Converting...' : 'Convert'}
//           </button>
//         </div>
//         <div className="col">
//           <button
//             className="btn btn-success btn-block"
//             onClick={downloadConvertedFiles}
//             disabled={!downloadUrl}
//           >
//             {isDownloading ? 'Downloading...' : 'Download Converted Files'}
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default FileUpload;



// import React, { useState } from 'react';
// import Swal from 'sweetalert2';
// import { saveAs } from 'file-saver';
// import { useDropzone } from 'react-dropzone';
// import './App.css';


// const FileUpload = () => {
//   const [files, setFiles] = useState([]);
//   const [isLoading, setIsLoading] = useState(false);
//   const [downloadUrl, setDownloadUrl] = useState(null);
//   const [isDownloading, setIsDownloading] = useState(false); // New state for download loading

//   const onDrop = acceptedFiles => {
//     setFiles([...files, ...acceptedFiles]);
//   };

//   const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: '.eml' });

//   const convertFiles = async () => {
//     if (files.length === 0 || isLoading) {
//       return;
//     }

//     setIsLoading(true);

//     // Send files to backend for conversion
//     const formData = new FormData();
//     files.forEach(file => formData.append('emailFiles', file));

//     try {
//       // const response = await fetch('https://msg.quickcoupondeals.com/convert', {
//       const response = await fetch('http://localhost:4008/convert', {
//         method: 'POST',
//         body: formData,
//       });

//       if (response.ok) {
//         const result = await response.json();
//         setDownloadUrl(result.zipPath);
//         Swal.fire({
//           icon: 'success',
//           title: 'Conversion Successful',
//           text: 'Your files have been converted successfully.',
//         });
//       } else {
//         throw new Error('Conversion failed');
//       }
//     } catch (error) {
//       console.error('Error converting files:', error);
//       Swal.fire({
//         icon: 'error',
//         title: 'Conversion Error',
//         text: 'An error occurred during conversion. Please try again.',
//       });
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const downloadConvertedFiles = async () => {
//     if (!downloadUrl || isDownloading) { // Check if download is already in progress
//       return;
//     }

//     setIsDownloading(true); // Set download loading state

//     // const fullUrl = `https://msg.quickcoupondeals.com${downloadUrl}`;
//     const fullUrl = `http://localhost:4008${downloadUrl}`;

//     try {
//       const response = await fetch(fullUrl, {
//         method: 'GET',
//       });

//       if (response.ok) {
//         const blob = await response.blob();
//         saveAs(blob, 'converted_files.zip');
//       } else {
//         throw new Error('Download failed');
//       }
//     } catch (error) {
//       console.error('Error downloading files:', error);
//       Swal.fire({
//         icon: 'error',
//         title: 'Download Error',
//         text: 'An error occurred during download. Please try again.',
//       });
//     } finally {
//       setIsDownloading(false); // Reset download loading state
//     }
//   };

//   // const removeFile = index => {
//   //   const updatedFiles = files.filter((_, i) => i !== index);
//   //   setFiles(updatedFiles);
//   // };


//   const removeFile = index => {
//     const updatedFiles = [...files];
//     updatedFiles[index] = { ...updatedFiles[index], removed: true };
//     setFiles(updatedFiles);
    
//     // Delay the actual removal from the state to allow time for the animation
//     setTimeout(() => {
//       const filteredFiles = files.filter((_, i) => i !== index);
//       setFiles(filteredFiles);
//     }, 300); // the delay time to match the CSS transition duration
//   };
  

//   const firstFileName = files.length > 0 ? files[0].name : '';

//   return (
//     <div>
//       <div className="container" style={{ border: '2px dashed black', cursor: 'pointer', position: 'relative' }}>
//         <div className="row justify-content-center">
//           <div className="col-8">
//             <p className="text-center pt-5 pb-3">Preview your data on browser and keep your data safe on device</p>
//             <div
//               {...getRootProps()}
//               className="dropzone text-center p-3"
//               style={{ cursor: 'pointer' }}
//             >
//               <button className="btn btn-primary">
//                 <input {...getInputProps()} accept=".msg" />
//                 <p className="h4 mb-0 fw-bold py-3 text-white px-4 stretched-link">
//                   {files.length === 0 ? 'Select Files' : `${firstFileName} ${files.length > 1 ? `+${files.length - 1}` : ''}`}
//                 </p>
//               </button>
//               <p className="pt-4 pb-5">Your data stays safe on your device, and previews happen in your web browser</p>
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* {files.length > 0 && (
//         <div className="container mt-4" style={{ overflowX: 'auto' }}>
//           <table className="table">
//             <thead>
//               <tr>
//                 <th scope="col">File Name</th>
//                 <th scope="col">File Size</th>
//                 <th scope="col">Progress</th>
//                 <th scope="col">Actions</th>
//               </tr>
//             </thead>
//             <tbody>
//               {files.map((file, index) => (
//                 <tr key={index} className={file.removed ? "fade-out" : ""}>
//                   <td>{file.name}</td>
//                   <td>{(file.size / 1024).toFixed(2)} KB</td>
//                   <td>Pending </td>
//                   <td>
//                     <button className="btn btn-danger btn-sm" onClick={() => removeFile(index)}>×</button>
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>
//       )} */}

// {/* 
// {files.length > 0 && (
//   <div className="container mt-4" >
//     <div  className='row'>

//   <div className='col overflow-scroll overflow-y-scroll'>
//     <table className="table" >
//       <thead>
//         <tr>
//           <th scope="col">File Name</th>
//           <th scope="col">File Size</th>
//           <th scope="col">Progress</th>
//           <th scope="col">Actions</th>
//         </tr>
//       </thead>
//       <tbody>
//         {files.map((file, index) => (
//           <tr key={index} className={file.removed ? "fade-out" : ""}>
//             <td>{file.name}</td>
//             <td>{(file.size / 1024).toFixed(2)} KB</td>
//             <td>Pending </td>
//             <td>
//               <button className="btn btn-danger btn-sm" onClick={() => removeFile(index)}>×</button>
//             </td>
//           </tr>
//         ))}
//       </tbody>
//     </table>
//     </div>
//     </div>
//   </div>
// )} */}



// {files.length > 0 && (
//   <div className="container mt-4">
//     <div className="row">
//       <div className="col" style={{ maxHeight: '300px', overflowX: 'auto', overflowY: 'scroll' }}>
//         <table className="table">
//           <thead>
//             <tr>
//               <th scope="col">File Name</th>
//               <th scope="col">File Size</th>
//               <th scope="col">Progress</th>
//               <th scope="col">Actions</th>
//             </tr>
//           </thead>
//           <tbody>
//             {files.map((file, index) => (
//               <tr key={index} className={file.removed ? "fade-out" : ""}>
//                 <td>{file.name}</td>
//                 <td>{(file.size / 1024).toFixed(2)} KB</td>
//                 <td>Pending {/* Replace with file status */}</td>
//                 <td>
//                   <button className="btn btn-danger btn-sm" onClick={() => removeFile(index)}>×</button>
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   </div>
// )}


//       <div className="row mt-3">
//         <div className="col text-center">
//           <button
//             className="btn btn-primary btn-block"
//             onClick={convertFiles}
//             disabled={isLoading || files.length === 0}
//           >
//             {isLoading ? 'Converting...' : 'Convert'}
//           </button>
//         </div>
//         <div className="col">
//           <button
//             className="btn btn-success btn-block"
//             onClick={downloadConvertedFiles}
//             disabled={!downloadUrl}
//           >
//             {isDownloading ? 'Downloading...' : 'Download Converted Files'}
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default FileUpload;

// {   UPDATED CODE ---------------------------------------------------------------------------}

// import React, { useState } from 'react';
// import Swal from 'sweetalert2';
// import { saveAs } from 'file-saver';
// import { useDropzone } from 'react-dropzone';
// import './App.css';

// const FileUpload = () => {
//   const [files, setFiles] = useState([]);
//   const [isLoading, setIsLoading] = useState(false);
//   const [downloadUrl, setDownloadUrl] = useState(null);
//   const [isDownloading, setIsDownloading] = useState(false); // New state for download loading

//   const onDrop = acceptedFiles => {
//     setFiles([...files, ...acceptedFiles]);
//   };

//   const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: '.eml' });

//   const convertFiles = async () => {
//     if (files.length === 0 || isLoading) {
//       return;
//     }

//     setIsLoading(true);

//     // Send files to backend for conversion
//     const formData = new FormData();
//     files.forEach(file => formData.append('emailFiles', file));

//     try {
//       // const response = await fetch('https://msg.quickcoupondeals.com/convert', {
//       const response = await fetch('http://localhost:4008/convert', {
//         method: 'POST',
//         body: formData,
//       });

//       if (response.ok) {
//         const result = await response.json();
//         setDownloadUrl(result.zipPath);
//         Swal.fire({
//           icon: 'success',
//           title: 'Conversion Successful',
//           text: 'Your files have been converted successfully.',
//         });
//       } else {
//         throw new Error('Conversion failed');
//       }
//     } catch (error) {
//       console.error('Error converting files:', error);
//       Swal.fire({
//         icon: 'error',
//         title: 'Conversion Error',
//         text: 'An error occurred during conversion. Please try again.',
//       });
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const downloadConvertedFiles = async () => {
//     if (!downloadUrl || isDownloading) { // Check if download is already in progress
//       return;
//     }

//     setIsDownloading(true); // Set download loading state

//     // const fullUrl = `https://msg.quickcoupondeals.com${downloadUrl}`;
//     const fullUrl = `http://localhost:4008${downloadUrl}`;

//     try {
//       const response = await fetch(fullUrl, {
//         method: 'GET',
//       });

//       if (response.ok) {
//         const blob = await response.blob();
//         saveAs(blob, 'converted_files.zip');
//       } else {
//         throw new Error('Download failed');
//       }
//     } catch (error) {
//       console.error('Error downloading files:', error);
//       Swal.fire({
//         icon: 'error',
//         title: 'Download Error',
//         text: 'An error occurred during download. Please try again.',
//       });
//     } finally {
//       setIsDownloading(false); // Reset download loading state
//       setDownloadUrl(null); // Clear download URL after completion
//     }
//   };

//   const removeFile = index => {
//     const updatedFiles = [...files];
//     updatedFiles[index] = { ...updatedFiles[index], removed: true };
//     setFiles(updatedFiles);

//     // Delay the actual removal from the state to allow time for the animation
//     setTimeout(() => {
//       const filteredFiles = files.filter((_, i) => i !== index);
//       setFiles(filteredFiles);
//     }, 300); // the delay time to match the CSS transition duration
//   };

//   const firstFileName = files.length > 0 ? files[0].name : '';

//   return (
//     <div>
//       <div className="container" style={{ border: '2px dashed black', cursor: 'pointer', position: 'relative' }}>
//         <div className="row justify-content-center">
//           <div className="col-8">
//             <p className="text-center pt-5 pb-3">Preview your data on browser and keep your data safe on device</p>
//             <div
//               {...getRootProps()}
//               className="dropzone text-center p-3"
//               style={{ cursor: 'pointer' }}
//             >
//               <button className="btn btn-primary">
//                 <input {...getInputProps()} accept=".msg" />
//                 <p className="h4 mb-0 fw-bold py-3 text-white px-4 stretched-link">
//                   {files.length === 0 ? 'Select Files' : `${firstFileName} ${files.length > 1 ? `+${files.length - 1}` : ''}`}
//                 </p>
//               </button>
//               <p className="pt-4 pb-5">Your data stays safe on your device, and previews happen in your web browser</p>
//             </div>
//           </div>
//         </div>
//       </div>

//       {downloadUrl && (
//         <div className="row mt-3">
//           <div className="col text-center">
//             <button
//               className="btn btn-success btn-block custom-download-button"
//               onClick={downloadConvertedFiles}
//               disabled={isDownloading}
//             >
//               {isDownloading ? 'Downloading...' : 'Download Converted Files'}
//             </button>
//           </div>
//         </div>
//       )}

//       {!downloadUrl && (
//         <div className="row mt-3">
//           <div className="col text-center">
//           <button
//   className="btn btn-primary btn-block custom-convert-button"
//   onClick={convertFiles}
//   disabled={isLoading || files.length === 0}
// >
//   {isLoading ? 'Converting...' : 'Convert'}
// </button>

//           </div>
//         </div>
//       )}

//       {isDownloading && (
//         <div className="text-center mt-3">
//           <div className="spinner-border text-primary" role="status">
//             <span className="visually-hidden">Loading...</span>
//           </div>
//         </div>
//       )}

//       {files.length > 0 && (
//         <div className="container mt-4">
//           <div className="row">
//             <div className="col" style={{ maxHeight: '300px', overflowX: 'auto', overflowY: 'scroll' }}>
//               <table className="table">
//                 <thead>
//                   <tr>
//                     <th scope="col">File Name</th>
//                     <th scope="col">File Size</th>
//                     <th scope="col">Progress</th>
//                     <th scope="col">Actions</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {files.map((file, index) => (
//                     <tr key={index} className={file.removed ? "fade-out" : ""}>
//                       <td>{file.name}</td>
//                       <td>{(file.size / 1024).toFixed(2)} KB</td>
//                       <td>Pending {/* Replace with file status */}</td>
//                       <td>
// {file.removed ? (
//   <span className="text-success">&#10003;</span>
// ) : (
//   <button className="btn btn-danger btn-sm" onClick={() => removeFile(index)}>×</button>
// )}
                        
//                       </td>
//                     </tr>
//                   ))}
//                 </tbody>
//               </table>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default FileUpload;


// {End line -----------------------------------------------------------------------------}
// Import useState from React
// import React, { useState } from 'react';
// import Swal from 'sweetalert2';
// import { saveAs } from 'file-saver';
// import { useDropzone } from 'react-dropzone';
// import './App.css';

// const FileUpload = () => {
//   const [files, setFiles] = useState([]);
//   const [isLoading, setIsLoading] = useState(false);
//   const [downloadUrl, setDownloadUrl] = useState(null);
//   const [isDownloading, setIsDownloading] = useState(false); // New state for download loading
//   const [saveLog, setSaveLog] = useState(false); // New state for saving log
//   const [conversionComplete, setConversionComplete] = useState(false); // New state for conversion completion

//   const onDrop = acceptedFiles => {
//     setFiles([...files, ...acceptedFiles]);
//   };

//   const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: '.eml' });

//   const convertFiles = async () => {
//     if (files.length === 0 || isLoading) {
//       return;
//     }

//     setIsLoading(true);

//     // Send files to backend for conversion
//     const formData = new FormData();
//     files.forEach(file => formData.append('emailFiles', file));
//     formData.append('saveLog', saveLog); // Pass saveLog state to the backend

//     try {
//         // const response = await fetch('https://msg.quickcoupondeals.com/convert', {
//       const response = await fetch('http://localhost:4008/convert', {
//         method: 'POST',
//         body: formData,
//       });

//       if (response.ok) {
//         const result = await response.json();
//         setDownloadUrl(result.zipPath);
//         Swal.fire({
//           icon: 'success',
//           title: 'Conversion Successful',
//           text: 'Your files have been converted successfully.',
//         });
//         setConversionComplete(true); // Set conversion completion state to true
//       } else {
//         throw new Error('Conversion failed');
//       }
//     } catch (error) {
//       console.error('Error converting files:', error);
//       Swal.fire({
//         icon: 'error',
//         title: 'Conversion Error',
//         text: 'An error occurred during conversion. Please try again.',
//       });
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const downloadConvertedFiles = async () => {
//     if (!downloadUrl || isDownloading) {
//       return;
//     }
  
//     setIsDownloading(true);
//   // const fullUrl = `https://msg.quickcoupondeals.com${downloadUrl}`;
//     const fullUrl = `http://localhost:4008${downloadUrl}`;
  
//     try {
//       const response = await fetch(fullUrl, {
//         method: 'GET',
//       });
  
//       if (response.ok) {
//         const blob = await response.blob();
//         saveAs(blob, 'converted_files.zip');
//       } else {
//         throw new Error('Download failed');
//       }
//     } catch (error) {
//       console.error('Error downloading files:', error);
//       Swal.fire({
//         icon: 'error',
//         title: 'Download Error',
//         text: 'An error occurred during download. Please try again.',
//       });
//     } finally {
//       setIsDownloading(false);
//       setDownloadUrl(null);
//       window.location.reload();
//     }
//   };
  
//   const removeFile = index => {
//     const updatedFiles = [...files];
//     updatedFiles[index] = { ...updatedFiles[index], removed: true };
//     setFiles(updatedFiles);

//     setTimeout(() => {
//       const filteredFiles = files.filter((_, i) => i !== index);
//       setFiles(filteredFiles);
//     }, 300);
//   };

//   const firstFileName = files.length > 0 ? files[0].name : '';

//   return (
//     <div>
//       <div className="container" style={{ border: '2px dashed black', cursor: 'pointer', position: 'relative' }}>
//         <div className="row justify-content-center">
//           <div className="col-8">
//             <p className="text-center pt-5 pb-3">Preview your data on browser and keep your data safe on device</p>
//             <div
//               {...getRootProps()}
//               className="dropzone text-center p-3"
//               style={{ cursor: 'pointer' }}
//             >
//               <button className="btn btn-primary">
//                 <input {...getInputProps()} accept=".msg" />
//                 <p className="h4 mb-0 fw-bold py-3 text-white px-4 stretched-link">
//                   {files.length === 0 ? 'Select Files' : `${firstFileName} ${files.length > 1 ? `+${files.length - 1}` : ''}`}
//                 </p>
//               </button>
//               <p className="pt-4 pb-5">Your data stays safe on your device, and previews happen in your web browser</p>
//             </div>
//           </div>
//         </div>
//       </div>

//       {downloadUrl && (
//         <div className="row mt-3">
//           <div className="col text-center">
//             <button
//               className="btn btn-success btn-block custom-download-button"
//               onClick={downloadConvertedFiles}
//               disabled={isDownloading}
//             >
//               {isDownloading ? 'Downloading...' : 'Download Converted Files'}
//             </button>
//           </div>
//         </div>
//       )}

//       {!downloadUrl && (
//         <div className="row mt-3">
//           <div className="col text-center">
//             <button
//               className="btn btn-primary btn-block custom-convert-button"
//               onClick={convertFiles}
//               disabled={isLoading || files.length === 0}
//             >
//               {isLoading ? 'Converting...' : 'Convert'}
//             </button>
//             <label className="form-check-label mt-2 float-end">
//               <input
//                 type="checkbox"
//                 className="form-check-input"
//                 checked={saveLog}
//                 onChange={e => setSaveLog(e.target.checked)}
//               />
//               Save Conversion Log
//             </label>
//           </div>
//         </div>
//       )}

//       {isDownloading && (
//         <div className="text-center mt-3">
//           <div className="spinner-border text-primary" role="status">
//             <span className="visually-hidden">Loading...</span>
//           </div>
//         </div>
//       )}

//       {files.length > 0 && (
//         <div className="container mt-4">
//           <div className="row">
//             <div className="col" style={{ maxHeight: '200px', overflowX: 'auto', overflowY: 'scroll' }}>
//               <table className="table">
//                 <thead>
//                   <tr>
//                     <th scope="col">File Name</th>
//                     <th scope="col">File Size</th>
//                     <th scope="col">Progress</th>
//                     <th scope="col">Actions</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {files.map((file, index) => (
//                     <tr key={index} className={file.removed ? "fade-out" : ""}>
//                       <td>{file.name}</td>
//                       <td>{(file.size / 1024).toFixed(2)} KB</td>
//                       <td>{conversionComplete ? 'Done' : 'Pending'}</td> {/* Conditionally render 'Done' or 'Pending' */}
//                       <td>
//                         {file.removed ? (
//                           <span className="text-success">&#10003;</span>
//                         ) : (
//                           <button className="btn btn-danger btn-sm" onClick={() => removeFile(index)}>×</button>
//                         )}
//                       </td>
//                     </tr>
//                   ))}
//                 </tbody>
//               </table>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default FileUpload;

// import React, { useState } from 'react';
// import Swal from 'sweetalert2';
// import { saveAs } from 'file-saver';
// import { useDropzone } from 'react-dropzone';
// import ProgressBar from 'react-bootstrap/ProgressBar';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faFilePdf, faEye, faDownload, faTimes } from '@fortawesome/free-solid-svg-icons';
// import './App.css';

// const FileUpload = () => {
//   const [files, setFiles] = useState([]);
//   const [isLoading, setIsLoading] = useState(false);
//   const [downloadUrl, setDownloadUrl] = useState(null);
//   const [isDownloading, setIsDownloading] = useState(false);
//   const [saveLog, setSaveLog] = useState(false);
//   const [conversionComplete, setConversionComplete] = useState(false);

//   const onDrop = acceptedFiles => {
//     setFiles([...files, ...acceptedFiles]);
//   };

//   const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: '.eml' });

//   const convertFiles = async () => {
//     if (files.length === 0 || isLoading) {
//       return;
//     }

//     setIsLoading(true);

//     const formData = new FormData();
//     files.forEach(file => formData.append('emailFiles', file));
//     formData.append('saveLog', saveLog);

//     try {
//       //  const response = await fetch('https://msg.quickcoupondeals.com/convert', {
//       const response = await fetch('http://localhost:4008/convert', {
//         method: 'POST',
//         body: formData,
//       });

//       if (response.ok) {
//         const result = await response.json();
//         setDownloadUrl(result.zipPath);
//         Swal.fire({
//           icon: 'success',
//           title: 'Conversion Successful',
//           text: 'Your files have been converted successfully.',
//         });
//         setConversionComplete(true);
//       } else {
//         throw new Error('Conversion failed');
//       }
//     } catch (error) {
//       console.error('Error converting files:', error);
//       Swal.fire({
//         icon: 'error',
//         title: 'Conversion Error',
//         text: 'An error occurred during conversion. Please try again.',
//       });
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const downloadConvertedFiles = async () => {
//     if (!downloadUrl || isDownloading) {
//       return;
//     }
  
//     setIsDownloading(true);
//     //  const fullUrl = `https://msg.quickcoupondeals.com${downloadUrl}`;
//     const fullUrl = `http://localhost:4008${downloadUrl}`;
  
//     try {
//       const response = await fetch(fullUrl, {
//         method: 'GET',
//       });
  
//       if (response.ok) {
//         const blob = await response.blob();
//         saveAs(blob, 'converted_files.zip');
//       } else {
//         throw new Error('Download failed');
//       }
//     } catch (error) {
//       console.error('Error downloading files:', error);
//       Swal.fire({
//         icon: 'error',
//         title: 'Download Error',
//         text: 'An error occurred during download. Please try again.',
//       });
//     } finally {
//       setIsDownloading(false);
//       setDownloadUrl(null);
//       window.location.reload();
//     }
//   };
  
//   const removeFile = index => {
//     const updatedFiles = [...files];
//     updatedFiles[index] = { ...updatedFiles[index], removed: true };
//     setFiles(updatedFiles);

//     setTimeout(() => {
//       const filteredFiles = files.filter((_, i) => i !== index);
//       setFiles(filteredFiles);
//     }, 300);
//   };

//   const firstFileName = files.length > 0 ? files[0].name : '';

//   const previewPDF = async (fileName) => {
//     try {
//         const response = await fetch(`http://localhost:4008/preview-pdf/${fileName}`);
//         if (!response.ok) {
//             throw new Error('Failed to load PDF');
//         }
//         const blob = await response.blob();
//         const url = window.URL.createObjectURL(blob);

//         // Open the PDF file in a new tab/window for preview
//         window.open(url, '_blank');
//     } catch (error) {
//         console.error('Error loading PDF:', error);
//         Swal.fire({
//             icon: 'error',
//             title: 'Preview Error',
//             text: 'An error occurred while previewing the PDF. Please try again.',
//         });
//     }
// };

  

//   const downloadPDF = async () => {
//     if (downloadUrl) {
//       // const fullUrl = `https://msg.quickcoupondeals.com${downloadUrl}`;
//       const fullUrl = `http://localhost:4008${downloadUrl}`;

//       try {
//         const response = await fetch(fullUrl, {
//           method: 'GET',
//         });

//         if (response.ok) {
//           const blob = await response.blob();
//           saveAs(blob, 'converted_file.pdf'); // Assume PDF extension for converted files
//         } else {
//           throw new Error('Failed to load PDF');
//         }
//       } catch (error) {
//         console.error('Error loading PDF:', error);
//         Swal.fire({
//           icon: 'error',
//           title: 'Download Error',
//           text: 'An error occurred while downloading the PDF. Please try again.',
//         });
//       }
//     }
//   };





  
//   return (
//     <div>
//       {/* <div className="container" style={{ border: '2px dashed black', cursor: 'pointer', position: 'relative' }}>
//         <div className="row justify-content-center">
//           <div className="col-8">
//             <p className="text-center pt-5 pb-3">Preview your data on browser and keep your data safe on device</p>
//             <div
//               {...getRootProps()}
//               className="dropzone text-center p-3"
//               style={{ cursor: 'pointer' }}
//             >
//               <button className="btn btn-primary">
//                 <input {...getInputProps()} accept=".msg" />
//                 <p className="h4 mb-0 fw-bold py-3 text-white px-4 stretched-link">
//                   {files.length === 0 ? 'Select Files' : `${firstFileName} ${files.length > 1 ? `+${files.length - 1}` : ''}`}
//                 </p>
//               </button>
//               <p className="pt-4 pb-5">Your data stays safe on your device, and previews happen in your web browser</p>
//             </div>
//           </div>
//         </div>
//       </div> */}

// <div className="container" style={{ border: '2px dashed black', cursor: 'pointer', position: 'relative' }}>
//   <div className="row justify-content-center">
//     <div className="col-8">
//       <p className="text-center pt-5 pb-3">Preview your data on browser and keep your data safe on device</p>
//       <div
//         {...getRootProps()}
//         className="dropzone text-center p-3"
//         style={{ cursor: 'pointer' }}
//       >
//         <button className="btn btn-primary">
//           <input {...getInputProps()} accept=".msg" />
//           <p className="h4 mb-0 fw-bold py-3 text-white px-4 stretched-link">
//             {files.length === 0 ? 'Select Files' : `${firstFileName} ${files.length > 1 ? `+${files.length - 1}` : ''}`}
//           </p>
//         </button>
//         <p className="pt-4 pb-5">Your data stays safe on your device, and previews happen in your web browser</p>
//         {/* Progress bar */}
//         {isLoading && (
//           <ProgressBar  striped variant="info" animated now={100} label={`Files  converting...`} />
//         )}
//       </div>
//     </div>
//   </div>
// </div>


//       {(files.length > 0 && !conversionComplete) && (
//         <div className="row mt-3">
//           <div className="col text-center">
//             <button
//               className="btn btn-primary btn-block custom-convert-button"
//               onClick={convertFiles}
//               disabled={isLoading}
//             >
//               {isLoading ? 'Converting...' : 'Convert'}
//             </button>
//             <label className="form-check-label mt-2 float-end ">
//               <input
//                 type="checkbox"
//                 className="form-check-input"
//                 checked={saveLog}
//                 onChange={e => setSaveLog(e.target.checked)}
//               />
//                      &nbsp; Save Conversion Log
//             </label>
//           </div>
//         </div>
//       )}

// {conversionComplete && (
//   <div className="row mt-3">
//     <div className="col text-center">
//       <button
//         className="btn btn-success btn-block custom-download-button"
//         onClick={downloadConvertedFiles}
//         disabled={isDownloading}
//       >
//         <FontAwesomeIcon icon={faDownload} className="mr-1" />
//          &nbsp;  {isDownloading ? 'Downloading...' : 'Download Converted Files'}
//       </button>
//     </div>
//   </div>
// )}

//       {isDownloading && (
//         <div className="text-center mt-3">
//           <div className="spinner-border text-primary" role="status">
//             <span className="visually-hidden">Loading...</span>
//           </div>
//         </div>
//       )}

// {files.length  > 0 && (

//       <div>
//        <p className='fw-bold'>Total Files Uploaded : {files.length}</p>
//       </div>
// )}


// {files.length > 0 && (
//   <div className="container mt-4">
//     <div className="row">
//       <div className="col" style={{ maxHeight: '200px', overflowX: 'auto', overflowY: 'scroll' }}>
//         <table className="table">
//           <thead>
//             <tr>
//               <th scope="col">File Name</th>
//               <th scope="col">File Size</th>
//               <th scope="col">Progress</th>
//               <th scope="col">Actions</th>
//               {conversionComplete && (
//                 <>
                
//                 <th scope="col">Preview Pdf</th>
//                 <th scope="col">Download Pdf</th>
//                 </>
//               )}
//             </tr>
//           </thead>
//           <tbody>
//             {/* {files.map((file, index) => (
//               <tr key={index} className={file.removed ? "fade-out" : ""}>
//                 <td>{file.name}</td>
//                 <td>{(file.size / 1024).toFixed(2)} KB</td>
//                 <td>{conversionComplete ? 'Done' : 'Pending'}</td>
//                 <td>
//                   {file.removed ? (
//                     <span className="text-success">&#10003;</span>
//                   ) : (
//                     <button className="btn btn-danger btn-sm" onClick={() => removeFile(index)}>×</button>
//                   )}
//                 </td>
//                 {conversionComplete && (
//                   <>
//                     <td>
//                     <button className="btn btn-primary btn-sm" onClick={() => previewPDF(file.name)}>Preview</button>
//                     </td>
//                     <td>
//                       <button className="btn btn-success btn-sm" onClick={() => downloadPDF(file)}>Download</button>
//                     </td>
//                   </>
//                 )}
//               </tr>
//             ))} */}
//             {files.map((file, index) => (
//   <tr key={index} className={file.removed ? "fade-out" : ""}>
//     <td>
//       <FontAwesomeIcon icon={faFilePdf} className="mr-2" /> {file.name}
//     </td>
//     <td>{(file.size / 1024).toFixed(2)} KB</td>
//     <td>{conversionComplete ? 'Done' : 'Pending'}</td>
//     {/* <td>
//       {file.removed ? (
//         <span className="text-success"><FontAwesomeIcon icon={faTimes} /></span>
//       ) : (
//         <button className="btn btn-danger btn-sm" onClick={() => removeFile(index)}>
//           <FontAwesomeIcon icon={faTimes} className="mr-1" /> Remove
//         </button>
//       )}
//     </td> */}
//     {conversionComplete ? (
//   <td>
//     <span className="text-success">&#10003; File Converted</span>
//   </td>
// ) : (
//   <td>
//     <button className="btn btn-danger btn-sm" onClick={() => removeFile(index)}>
//       <FontAwesomeIcon icon={faTimes} className="mr-1" /> Remove
//     </button>
//   </td>
// )}

//     {conversionComplete && (
//       <>
//         <td>
//           <button className="btn btn-primary btn-sm" onClick={() => previewPDF(file.name)}>
//             <FontAwesomeIcon icon={faEye} className="mr-1" /> Preview
//           </button>
//         </td>
//         <td>
//           <button className="btn btn-success btn-sm" onClick={() => downloadPDF(file)}>
//             <FontAwesomeIcon icon={faDownload} className="mr-1" /> Download
//           </button>
//         </td>
//       </>
//     )}
//   </tr>
// ))}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   </div>
// )}


//     </div>
//   );
// };

// export default FileUpload;


import React, { useState } from 'react';
import Swal from 'sweetalert2';
import { saveAs } from 'file-saver';
import { useDropzone } from 'react-dropzone';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faEye, faDownload, faTimes } from '@fortawesome/free-solid-svg-icons';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { faFileUpload } from '@fortawesome/free-solid-svg-icons';
import './App.css';

const FileUpload = () => {
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState(null);
  const [isDownloading, setIsDownloading] = useState(false);
  const [inDownloading, setInDownloading] = useState(false);
  const [saveLog, setSaveLog] = useState(false);
  const [conversionComplete, setConversionComplete] = useState(false);

  const onDrop = acceptedFiles => {
    setFiles([...files, ...acceptedFiles]);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: '.eml' });

  const convertFiles = async () => {
    if (files.length === 0 || isLoading) {
      return;
    }

    setIsLoading(true);

    const formData = new FormData();
    files.forEach(file => formData.append('emailFiles', file));
    formData.append('saveLog', saveLog);

    try {
        const response = await fetch('https://msg.quickcoupondeals.com/convert', {
      // const response = await fetch('http://localhost:4008/convert', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        const result = await response.json();
        setDownloadUrl(result.zipPath);
        Swal.fire({
          icon: 'success',
          title: 'Conversion Successful',
          text: 'Your files have been converted successfully.',
        });
        setConversionComplete(true);
      } else {
        throw new Error('Conversion failed');
      }
    } catch (error) {
      console.error('Error converting files:', error);
      Swal.fire({
        icon: 'error',
        title: 'Conversion Error',
        text: 'An error occurred during conversion. Please try again.',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const downloadConvertedFiles = async () => {
    if (!downloadUrl || isDownloading) {
      return;
    }
  
    setIsDownloading(true);
    const fullUrl = `https://msg.quickcoupondeals.com${downloadUrl}`;
    // const fullUrl = `http://localhost:4008${downloadUrl}`;
  
    try {
      const response = await fetch(fullUrl, {
        method: 'GET',
      });
  
      if (response.ok) {
        const blob = await response.blob();
        saveAs(blob, 'converted_files.zip');
      } else {
        throw new Error('Download failed');
      }
    } catch (error) {
      console.error('Error downloading files:', error);
      Swal.fire({
        icon: 'error',
        title: 'Download Error',
        text: 'An error occurred during download. Please try again.',
      });
    } finally {
      setIsDownloading(false);
      setDownloadUrl(null);
      window.location.reload();
    }
  };
  
  const removeFile = index => {
    const updatedFiles = [...files];
    updatedFiles[index] = { ...updatedFiles[index], removed: true };
    setFiles(updatedFiles);

    setTimeout(() => {
      const filteredFiles = files.filter((_, i) => i !== index);
      setFiles(filteredFiles);
    }, 300);
  };

  const firstFileName = files.length > 0 ? files[0].name : '';

  const previewPDF = async (fileName) => {
    try {
        const response = await fetch(`https://msg.quickcoupondeals.com/preview-pdf/${fileName}`);
        if (!response.ok) {
            throw new Error('Failed to load PDF');
        }
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);

        // Open the PDF file in a new tab/window for preview
        window.open(url, '_blank');
    } catch (error) {
        console.error('Error loading PDF:', error);
        Swal.fire({
            icon: 'error',
            title: 'Preview Error',
            text: 'An error occurred while previewing the PDF. Please try again.',
        });
    }
};

  

  // const downloadPDF = async () => {
  //   if (downloadUrl) {
  //     const fullUrl = `http://localhost:4008${downloadUrl}`;

  //     try {
  //       const response = await fetch(fullUrl, {
  //         method: 'GET',
  //       });

  //       if (response.ok) {
  //         const blob = await response.blob();
  //         saveAs(blob, 'converted_file.pdf'); // Assume PDF extension for converted files
  //       } else {
  //         throw new Error('Failed to load PDF');
  //       }
  //     } catch (error) {
  //       console.error('Error loading PDF:', error);
  //       Swal.fire({
  //         icon: 'error',
  //         title: 'Download Error',
  //         text: 'An error occurred while downloading the PDF. Please try again.',
  //       });
  //     }
  //   }
  // };


  const downloadPDF = async () => {
    if (downloadUrl) {
      // const fullUrl = `http://localhost:4008${downloadUrl}`;
      const fullUrl = `https://msg.quickcoupondeals.com${downloadUrl}`;
  
      try {
        setInDownloading(true); // Set loading state to true
  
        const response = await fetch(fullUrl, {
          method: 'GET',
        });
  
        if (response.ok) {
          const blob = await response.blob();
          saveAs(blob, 'converted_file.pdf'); // Assume PDF extension for converted files
        } else {
          throw new Error('Failed to load PDF');
        }
      } catch (error) {
        console.error('Error loading PDF:', error);
        Swal.fire({
          icon: 'error',
          title: 'Download Error',
          text: 'An error occurred while downloading the PDF. Please try again.',
        });
      } finally {
        setInDownloading(false); // Set loading state to false after download attempt
      }
    }
  };
  
  return (
    <div>
      <div className="container" style={{ border: '2px dashed black', cursor: 'pointer', position: 'relative' }}>
        <div className="row justify-content-center">
          <div className="col-8">
            <p className="text-center pt-5 pb-3">Preview your data on browser and keep your data safe on device</p>
            <div
              {...getRootProps()}
              className="dropzone text-center p-3"
              style={{ cursor: 'pointer' }}
            >
              <button className="btn btn-primary">
                <input {...getInputProps()} accept=".msg" />
                <p className="h4 mb-0 fw-bold py-3 text-white px-4 stretched-link">
                  {files.length === 0 ? 'Select Files' : `${firstFileName} ${files.length > 1 ? `+${files.length - 1}` : ''}`}
                </p>
              </button>
              <p className="pt-4 pb-5">Your data stays safe on your device, and previews happen in your web browser</p>
              {/* Progress bar */}
              {isLoading && (
                <ProgressBar  striped variant="info" animated now={100} label={`Files converting...`} />
              )}
            </div>
          </div>
        </div>
      </div>

      {(files.length > 0 && !conversionComplete) && (
        <div className="row mt-3">
          <div className="col text-center">
          <button
  className="btn btn-primary btn-block custom-convert-button"
  onClick={convertFiles}
  disabled={isLoading}
>
  {isLoading ? (
    <>
      <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
      Converting...
    </>
  ) : (
    <>
      <span className="me-1" aria-hidden="true">&#x21a6;</span>
      Convert
    </>
  )}
</button>


            <label className="form-check-label mt-2 float-end ">
              <input
                type="checkbox"
                className="form-check-input"
                checked={saveLog}
                onChange={e => setSaveLog(e.target.checked)}
              />
                     &nbsp; Save Conversion Log
            </label>
          </div>
        </div>
      )}

      {conversionComplete && (
        <div className="row mt-3">
          <div className="col text-center">
            <button
              className="btn btn-success btn-block custom-download-button"
              onClick={downloadConvertedFiles}
              disabled={isDownloading}
            >
              <FontAwesomeIcon icon={faDownload} className="mr-1" />
               &nbsp;  {isDownloading ? 'Downloading...' : 'Download Converted Files'}
            </button>
          </div>
        </div>
      )}

      {isDownloading && (
        <div className="text-center mt-3">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}

{files.length > 0 && (
  <div>
    <p className='fw-bold'>
      <FontAwesomeIcon icon={faFileUpload} className="me-2" />
      Total Files Uploaded: {files.length}
    </p>
  </div>
)}

      {files.length > 0 && (
        <div className="container mt-4">
          <div className="row">
            <div className="col" style={{ maxHeight: '200px', overflowX: 'auto', overflowY: 'scroll' }}>
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">File Name</th>
                    <th scope="col">File Size</th>
                    <th scope="col">Progress</th>
                    <th scope="col">Actions</th>
                    {conversionComplete && (
                      <>
                        <th scope="col">Preview Pdf</th>
                        <th scope="col">Download Pdf</th>
                      </>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {files.map((file, index) => (
                    <tr key={index} className={file.removed ? "fade-out" : ""}>
                      <td>
                        <FontAwesomeIcon icon={faFilePdf} className="mr-2" /> {file.name}
                      </td>
                      <td>{(file.size / 1024).toFixed(2)} KB</td>
                      <td>{conversionComplete ? 'Done' : 'Pending'}</td>
                      {conversionComplete ? (
                        <td>
                          <span className="text-success">&#10003; File Converted</span>
                        </td>
                      ) : (
                        <td>
                          <button className="btn btn-danger btn-sm" onClick={() => removeFile(index)}>
                            <FontAwesomeIcon icon={faTimes} className="mr-1" /> Remove
                          </button>
                        </td>
                      )}
                      {conversionComplete && (
                        <>
                          <td>
                            <button className="btn btn-primary btn-sm" onClick={() => previewPDF(file.name)}>
                              <FontAwesomeIcon icon={faEye} className="mr-1" /> Preview
                            </button>
                          </td>
                          <td>
                          <button className="btn btn-success btn-sm" onClick={() => downloadPDF(file)} disabled={isDownloading}>
  {inDownloading ? (
    <>
      <div className="spinner-border spinner-border-sm" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
      <span className="ms-1">Downloading...</span>
    </>
  ) : (
    <>
      <FontAwesomeIcon icon={faDownload} className="mr-1" /> Download
    </>
  )}
</button>

</td>

                        </>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FileUpload;


// {NEw update code according to progress status  Start --------------------------------------------------------------------------------}
// import React, { useState, useEffect } from 'react';
// import Swal from 'sweetalert2';
// import { saveAs } from 'file-saver';
// import { useDropzone } from 'react-dropzone';
// import io from 'socket.io-client'; // Import Socket.IO client
// import './App.css';

// const FileUpload = () => {
//   const [files, setFiles] = useState([]);
//   const [isLoading, setIsLoading] = useState(false);
//   const [downloadUrl, setDownloadUrl] = useState(null);
//   const [isDownloading, setIsDownloading] = useState(false); // New state for download loading
//   const [fileStatus, setFileStatus] = useState({}); // State for progress status

//   const socket = io('http://localhost:4008'); // Connect to the backend server

//   useEffect(() => {
//     // Listen for 'conversionComplete' event from the server
//     socket.on('conversionComplete', ({ zipPath }) => {
//       setDownloadUrl(zipPath);
//       setIsLoading(false); // Reset loading state
//       Swal.fire({
//         icon: 'success',
//         title: 'Conversion Successful',
//         text: 'Your files have been converted successfully.',
//       });
//     });

//     // Listen for 'progressUpdate' event from the server
//     socket.on('progressUpdate', ({ fileName, progress }) => {
//       setFileStatus(prevState => ({
//         ...prevState,
//         [fileName]: progress,
//       }));
//     });

//     // Clean up event listener when component unmounts
//     return () => {
//       socket.disconnect();
//     };
//   }, [socket]);

//   const onDrop = acceptedFiles => {
//     setFiles([...files, ...acceptedFiles]);
//   };

//   const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: '.eml' });

//   const convertFiles = async () => {
//     if (files.length === 0 || isLoading) {
//       return;
//     }

//     setIsLoading(true);

//     // Send files to backend for conversion
//     const formData = new FormData();
//     files.forEach(file => formData.append('emailFiles', file));

//     try {
//       const response = await fetch('http://localhost:4008/convert', {
//         method: 'POST',
//         body: formData,
//       });

//       if (!response.ok) {
//         throw new Error('Conversion failed');
//       }
//     } catch (error) {
//       console.error('Error converting files:', error);
//       setIsLoading(false); // Reset loading state
//       Swal.fire({
//         icon: 'error',
//         title: 'Conversion Error',
//         text: 'An error occurred during conversion. Please try again.',
//       });
//     }
//   };

//   const downloadConvertedFiles = async () => {
//     if (!downloadUrl || isDownloading) { // Check if download is already in progress
//       return;
//     }

//     setIsDownloading(true); // Set download loading state

//     const fullUrl = `http://localhost:4008${downloadUrl}`;

//     try {
//       const response = await fetch(fullUrl, {
//         method: 'GET',
//       });

//       if (response.ok) {
//         const blob = await response.blob();
//         saveAs(blob, 'converted_files.zip');
//       } else {
//         throw new Error('Download failed');
//       }
//     } catch (error) {
//       console.error('Error downloading files:', error);
//       Swal.fire({
//         icon: 'error',
//         title: 'Download Error',
//         text: 'An error occurred during download. Please try again.',
//       });
//     } finally {
//       setIsDownloading(false); // Reset download loading state
//       setDownloadUrl(null); // Clear download URL after completion
//     }
//   };

//   const removeFile = index => {
//     const updatedFiles = [...files];
//     updatedFiles[index] = { ...updatedFiles[index], removed: true };
//     setFiles(updatedFiles);

//     // Delay the actual removal from the state to allow time for the animation
//     setTimeout(() => {
//       const filteredFiles = files.filter((_, i) => i !== index);
//       setFiles(filteredFiles);
//     }, 300); // the delay time to match the CSS transition duration
//   };

//   const firstFileName = files.length > 0 ? files[0].name : '';

//   return (
//     <div>
//       <div className="container" style={{ border: '2px dashed black', cursor: 'pointer', position: 'relative' }}>
//         <div className="row justify-content-center">
//           <div className="col-8">
//             <p className="text-center pt-5 pb-3">Preview your data on browser and keep your data safe on device</p>
//             <div
//               {...getRootProps()}
//               className="dropzone text-center p-3"
//               style={{ cursor: 'pointer' }}
//             >
//               <button className="btn btn-primary">
//                 <input {...getInputProps()} accept=".msg" />
//                 <p className="h4 mb-0 fw-bold py-3 text-white px-4 stretched-link">
//                   {files.length === 0 ? 'Select Files' : `${firstFileName} ${files.length > 1 ? `+${files.length - 1}` : ''}`}
//                 </p>
//               </button>
//               <p className="pt-4 pb-5">Your data stays safe on your device, and previews happen in your web browser</p>
//             </div>
//           </div>
//         </div>
//       </div>

//       {downloadUrl && (
//         <div className="row mt-3">
//           <div className="col text-center">
//             <button
//               className="btn btn-success btn-block custom-download-button"
//               onClick={downloadConvertedFiles}
//               disabled={isDownloading}
//             >
//               {isDownloading ? 'Downloading...' : 'Download Converted Files'}
//             </button>
//           </div>
//         </div>
//       )}

//       {!downloadUrl && (
//         <div className="row mt-3">
//           <div className="col text-center">
//             <button
//               className="btn btn-primary btn-block custom-convert-button"
//               onClick={convertFiles}
//               disabled={isLoading || files.length === 0}
//             >
//               {isLoading ? 'Converting...' : 'Convert'}
//             </button>
//           </div>
//         </div>
//       )}

//       {isDownloading && (
//         <div className="text-center mt-3">
//           <div className="spinner-border text-primary" role="status">
//             <span className="visually-hidden">Loading...</span>
//           </div>
//         </div>
//       )}

//       {files.length > 0 && (
//         <div className="container mt-4">
//           <div className="row">
//             <div className="col" style={{ maxHeight: '300px', overflowX: 'auto', overflowY: 'scroll' }}>
//               <table className="table">
//                 <thead>
//                   <tr>
//                     <th scope="col">File Name</th>
//                     <th scope="col">File Size</th>
//                     <th scope="col">Progress</th>
//                     <th scope="col">Actions</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {files.map((file, index) => (
//                     <tr key={index} className={file.removed ? "fade-out" : ""}>
//                       <td>{file.name}</td>
//                       <td>{(file.size / 1024).toFixed(2)} KB</td>
//                       <td>{fileStatus[file.name] !== undefined ? fileStatus[file.name] : 'Pending'}</td> 
//                       <td>
//                         {file.removed ? (
//                           <span className="text-success">&#10003;</span>
//                         ) : (
//                           <button className="btn btn-danger btn-sm" onClick={() => removeFile(index)}>×</button>
//                         )}
//                       </td>
//                     </tr>
//                   ))}
//                 </tbody>
//               </table>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default FileUpload;


// {NEw update code according to progress status End --------------------------------------------------------------------------------}





// // Import useState and useEffect hooks
// import React, { useState, useEffect } from 'react';
// import Swal from 'sweetalert2';
// import { saveAs } from 'file-saver';
// import { useDropzone } from 'react-dropzone';
// import './App.css';

// const FileUpload = () => {
//   const [files, setFiles] = useState([]);
//   const [isLoading, setIsLoading] = useState(false);
//   const [downloadUrl, setDownloadUrl] = useState(null);
//   const [isDownloading, setIsDownloading] = useState(false);

//   // New state to track the progress status of each file
//   const [fileStatus, setFileStatus] = useState({});

//   const onDrop = acceptedFiles => {
//     setFiles([...files, ...acceptedFiles]);
//   };

//   const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: '.eml' });

//   const convertFiles = async () => {
//     if (files.length === 0 || isLoading) {
//       return;
//     }

//     setIsLoading(true);

//     const formData = new FormData();
//     files.forEach(file => formData.append('emailFiles', file));

//     try {
//       const response = await fetch('http://localhost:4008/convert', {
//         method: 'POST',
//         body: formData,
//       });

//       if (response.ok) {
//         const result = await response.json();
//         setDownloadUrl(result.zipPath);
//         Swal.fire({
//           icon: 'success',
//           title: 'Conversion Successful',
//           text: 'Your files have been converted successfully.',
//         });
//       } else {
//         throw new Error('Conversion failed');
//       }
//     } catch (error) {
//       console.error('Error converting files:', error);
//       Swal.fire({
//         icon: 'error',
//         title: 'Conversion Error',
//         text: 'An error occurred during conversion. Please try again.',
//       });
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const downloadConvertedFiles = async () => {
//     if (!downloadUrl || isDownloading) {
//       return;
//     }

//     setIsDownloading(true);

//     const fullUrl = `http://localhost:4008${downloadUrl}`;

//     try {
//       const response = await fetch(fullUrl, {
//         method: 'GET',
//       });

//       if (response.ok) {
//         const blob = await response.blob();
//         saveAs(blob, 'converted_files.zip');
//       } else {
//         throw new Error('Download failed');
//       }
//     } catch (error) {
//       console.error('Error downloading files:', error);
//       Swal.fire({
//         icon: 'error',
//         title: 'Download Error',
//         text: 'An error occurred during download. Please try again.',
//       });
//     } finally {
//       setIsDownloading(false);
//       setDownloadUrl(null);
//     }
//   };

//   const removeFile = index => {
//     const updatedFiles = [...files];
//     updatedFiles[index] = { ...updatedFiles[index], removed: true };
//     setFiles(updatedFiles);

//     setTimeout(() => {
//       const filteredFiles = files.filter((_, i) => i !== index);
//       setFiles(filteredFiles);
//     }, 300);
//   };

//   const firstFileName = files.length > 0 ? files[0].name : '';

//   // Update progress status when files change
//   useEffect(() => {
//     const interval = setInterval(() => {
//       if (!isLoading) return; // Don't update if not loading
//       const newStatus = {};
//       files.forEach(file => {
//         if (!fileStatus[file.name]) {
//           // Initialize status if not set
//           newStatus[file.name] = 'Converting...';
//         }
//       });
//       setFileStatus(newStatus);
//     }, 1000); // Update every second
//     return () => clearInterval(interval);
//   }, [isLoading, files, fileStatus]);

//   return (
//     <div>
//       {/* Your existing code */}

//       {files.length > 0 && (
//         <div className="container mt-4">
//           <div className="row">
//             <div className="col" style={{ maxHeight: '300px', overflowX: 'auto', overflowY: 'scroll' }}>
//               <table className="table">
//                 <thead>
//                   <tr>
//                     <th scope="col">File Name</th>
//                     <th scope="col">File Size</th>
//                     <th scope="col">Progress</th>
//                     <th scope="col">Actions</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {files.map((file, index) => (
//                     <tr key={index} className={file.removed ? "fade-out" : ""}>
//                       <td>{file.name}</td>
//                       <td>{(file.size / 1024).toFixed(2)} KB</td>
//                       <td>{fileStatus[file.name]}</td> {/* Display progress status */}
//                       <td>
//                         {/* Show cross or tick mark based on status */}
//                         {!fileStatus[file.name] || fileStatus[file.name] === 'Converting...' ? (
//                           <button className="btn btn-danger btn-sm" onClick={() => removeFile(index)}>×</button>
//                         ) : (
//                           <span className="text-success">&#10003;</span>
//                         )}
//                       </td>
//                     </tr>
//                   ))}
//                 </tbody>
//               </table>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default FileUpload;














// import React, { useState } from 'react';
// import Swal from 'sweetalert2';
// import { saveAs } from 'file-saver';
// import { useDropzone } from 'react-dropzone';
// import './App.css';

// const FileUpload = () => {
//   const [files, setFiles] = useState([]);
//   const [isLoading, setIsLoading] = useState(false);
//   const [downloadUrl, setDownloadUrl] = useState(null);
//   const [isDownloading, setIsDownloading] = useState(false);

//   const onDrop = acceptedFiles => {
//     setFiles([...files, ...acceptedFiles]);
//   };

//   const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: '.eml' });

//   const convertFiles = async () => {
//     if (files.length === 0 || isLoading) {
//       return;
//     }

//     setIsLoading(true);

//     const formData = new FormData();
//     files.forEach(file => formData.append('emailFiles', file));

//     try {
//       const response = await fetch('http://localhost:4008/convert', {
//         method: 'POST',
//         body: formData,
//       });

//       if (response.ok) {
//         const result = await response.json();
//         if (files.length === 1 && !files[0].attachments) {
//           // Single file without attachments, handle PDF directly
//           setDownloadUrl(result.pdfPath);
//         } else {
//           setDownloadUrl(result.zipPath);
//         }
//         Swal.fire({
//           icon: 'success',
//           title: 'Conversion Successful',
//           text: 'Your files have been converted successfully.',
//         });
//       } else {
//         throw new Error('Conversion failed');
//       }
//     } catch (error) {
//       console.error('Error converting files:', error);
//       Swal.fire({
//         icon: 'error',
//         title: 'Conversion Error',
//         text: 'An error occurred during conversion. Please try again.',
//       });
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const downloadConvertedFiles = async () => {
//     if (!downloadUrl || isDownloading) {
//       return;
//     }

//     setIsDownloading(true);

//     const fullUrl = `http://localhost:4008${downloadUrl}`;

//     try {
//       const response = await fetch(fullUrl, {
//         method: 'GET',
//       });

//       if (response.ok) {
//         const blob = await response.blob();
//         saveAs(blob, 'converted_files.zip');
//       } else {
//         throw new Error('Download failed');
//       }
//     } catch (error) {
//       console.error('Error downloading files:', error);
//       Swal.fire({
//         icon: 'error',
//         title: 'Download Error',
//         text: 'An error occurred during download. Please try again.',
//       });
//     } finally {
//       setIsDownloading(false);
//       setDownloadUrl(null);
//     }
//   };

//   const removeFile = index => {
//     const updatedFiles = [...files];
//     updatedFiles[index] = { ...updatedFiles[index], removed: true };
//     setFiles(updatedFiles);

//     setTimeout(() => {
//       const filteredFiles = files.filter((_, i) => i !== index);
//       setFiles(filteredFiles);
//     }, 300);
//   };

//   const firstFileName = files.length > 0 ? files[0].name : '';

//   return (
//     <div>
//       <div className="container" style={{ border: '2px dashed black', cursor: 'pointer', position: 'relative' }}>
//         <div className="row justify-content-center">
//           <div className="col-8">
//             <p className="text-center pt-5 pb-3">Preview your data on browser and keep your data safe on device</p>
//             <div
//               {...getRootProps()}
//               className="dropzone text-center p-3"
//               style={{ cursor: 'pointer' }}
//             >
//               <button className="btn btn-primary">
//                 <input {...getInputProps()} accept=".msg" />
//                 <p className="h4 mb-0 fw-bold py-3 text-white px-4 stretched-link">
//                   {files.length === 0 ? 'Select Files' : `${firstFileName} ${files.length > 1 ? `+${files.length - 1}` : ''}`}
//                 </p>
//               </button>
//               <p className="pt-4 pb-5">Your data stays safe on your device, and previews happen in your web browser</p>
//             </div>
//           </div>
//         </div>
//       </div>

//       {downloadUrl && (
//         <div className="row mt-3">
//           <div className="col text-center">
//             <button
//               className="btn btn-success btn-block custom-download-button"
//               onClick={downloadConvertedFiles}
//               disabled={isDownloading}
//             >
//               {isDownloading ? 'Downloading...' : 'Download Converted Files'}
//             </button>
//           </div>
//         </div>
//       )}

//       {!downloadUrl && (
//         <div className="row mt-3">
//           <div className="col text-center">
//             <button
//               className="btn btn-primary btn-block custom-convert-button"
//               onClick={convertFiles}
//               disabled={isLoading || files.length === 0}
//             >
//               {isLoading ? 'Converting...' : 'Convert'}
//             </button>
//           </div>
//         </div>
//       )}

//       {isDownloading && (
//         <div className="text-center mt-3">
//           <div className="spinner-border text-primary" role="status">
//             <span className="visually-hidden">Loading...</span>
//           </div>
//         </div>
//       )}

//       {files.length > 0 && (
//         <div className="container mt-4">
//           <div className="row">
//             <div className="col" style={{ maxHeight: '300px', overflowX: 'auto', overflowY: 'scroll' }}>
//               <table className="table">
//                 <thead>
//                   <tr>
//                     <th scope="col">File Name</th>
//                     <th scope="col">File Size</th>
//                     <th scope="col">Progress</th>
//                     <th scope="col">Actions</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {files.map((file, index) => (
//                     <tr key={index} className={file.removed ? "fade-out" : ""}>
//                       <td>{file.name}</td>
//                       <td>{(file.size / 1024).toFixed(2)} KB</td>
//                       <td>Pending {/* Replace with file status */}</td>
//                       <td>
//                         <button className="btn btn-danger btn-sm" onClick={() => removeFile(index)}>×</button>
//                       </td>
//                     </tr>
//                   ))}
//                 </tbody>
//               </table>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default FileUpload;
